import store from '@/state/store';
//eslint-disable-next-line
import { cloneDeep, isEqual, groupBy, filter, flatten } from 'lodash';
import { parseISO, getQuarter} from 'date-fns';
const gantt_state = store.state.gantt;
const activity_state = store.state.activity;

const quarterlyFilter = function(data){
    if(data.length == 0 || activity_state.objective_viewing_quarter == 0){
        return data;
    }
    let filtered_data = data.filter( item => {
        //* get starting date in correct format
        const item_date = parseISO(item.start_date);
        //* get the quarterly value of the start date
        const item_quarter = getQuarter(item_date);
        //* compare current items quarter with the viewing quarter
        const quarter_condition = activity_state.objective_viewing_quarter == item_quarter;
        return quarter_condition;
    })
    // filtered_data.forEach(( item )=>{
    //     //* find all the filtered items with missing parents, then soft update these items to be root nodes ( tree breaks with no parent root nodes)
    //     let found_index = filtered_data.findIndex( elem => {
    //         return item.parent_id == elem.id;
    //     })
    //     if(found_index == -1){
    //         item.parent_id = null;
    //         item.parent = 0;
    //     }
    // })

    //* sort so that keyresults are at the top
    const sortForKeyResult = (items) => {
        // Sort items based on type, prioritizing key_result
        items.sort((a, b) => {
            if (a.item_type === b.item_type) return 0; // No change if types are the same
            return a.item_type === 'key_result' ? -1 : 1; // key_result comes first
        });
    
        // Recursively apply sorting to children if they exist
        items.forEach(item => {
            if (item.data && item.data.length) {
                sortForKeyResult(item.data);
            }
        });
    }

    sortForKeyResult(filtered_data);
    
    return filtered_data;
    //return data;
}

class LocalBackend extends window.gantt.services.Backend {
    // collections
    tasks() {
        let filteredData = null
        const user = store.state.levels.selected_user_id
        if(gantt_state.pinned_gantt_tasks.length > 0){
            if(user == null) {
                let data = cloneDeep(gantt_state.pinned_gantt_tasks)
                filteredData = data.filter((ele) => ele.objective_type != 'personal')
            } else {
                filteredData = cloneDeep(gantt_state.pinned_gantt_tasks)
            }
            return window.webix.promise.resolve(filteredData);
        }
        else{
            if(user == null) {
                let data = cloneDeep(gantt_state.gantt_tasks)
                filteredData = data.filter((ele) => ele.objective_type != 'personal')
            } else {
                filteredData = cloneDeep(gantt_state.gantt_tasks)
            }
            //* else use the whole array to render gantt tasks
            return window.webix.promise.resolve(
                quarterlyFilter(filteredData)
            );
        }
    }
    position(p){
        console.log('Gantt position hook: ', p);
    }
    links() {
        return window.webix.promise.resolve([]);
    }
    // dummpy operations handlers
    addTask() {
        return window.webix.promise.resolve({ id: window.webix.uid() });
    }
    updateTask() {//id, item
        console.log('Gant task update hook')
        return window.webix.promise.resolve()
        
    }
    removeTask() {
        return window.webix.promise.resolve();
    }
    addLink() {
        return window.webix.promise.resolve({ id: window.webix.uid() });
    }
    updateLink() {
        return window.webix.promise.resolve();
    }
    removeLink() {
        return window.webix.promise.resolve();
    }
}

export { LocalBackend as default };